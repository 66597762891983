import axios from '../uitls/require'

export default {
  orderList(data){
    return axios.post('/admin/order/list',data)
  },
  orderRefund(id){
    return axios.post('/admin/order/refund',id)
  },
  resendEmail(id){
    return axios.post('/admin/order/email',id)
  },
  refundList(data){
    return axios.post('/admin/refund/list',data)
  },
  orderExport(data){
    return axios.post('/admin/order/export',data)
  },
  refundExport(data){
    return axios.post('/admin/refund/export',data)
  }
}