import axios from '../uitls/require'

export default {
  market() {
    return axios.get('/markets')
  },
//   // 图片上传
//   upLoad(data) {
//     return axios.post('/image/upload', data);
//   }
}
