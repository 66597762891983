<template>
  <div class="classification" v-if="hasResource('order_management')">
    <div class="integral-recharge">
      <div class="search-form">
        <el-button
          class="black-btn"
          type="primary"
          @click="exportData"
          :loading="isLoading"
          v-if="hasResource('order_export')"
          >导出</el-button
        >
        <div class="dis-fix">
          <el-form :model="filter" :inline="true">
            <el-form-item label="订单号">
              <el-input
                v-model="filter.rechargeId"
                placeholder="输入订单号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="下单时间">
              <el-date-picker
                v-model="rechargeTime"
                style="width: 168px"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="-"
                start-placeholder="请选择"
                end-placeholder="请选择"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="用户名称">
              <el-input
                v-model="filter.customer_username"
                placeholder="输入用户名检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="用户邮箱">
              <el-input
                v-model="filter.customer_email"
                placeholder="输入邮箱检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="方案编号">
              <el-input
                v-model="filter.rechargeNumber"
                placeholder="输入积分方案编号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="市场">
              <el-select
                v-model="filter.market"
                placeholder="请选择"
                class="select-size"
              >
                <el-option
                  v-for="(item, key) in market"
                  :key="key"
                  :label="item"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="交易单号">
              <el-input
                v-model="filter.trasactionId"
                placeholder="输入交易单号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="关联单号">
              <el-input
                v-model="filter.assaociatedNum"
                placeholder="输入关联单号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="rechargeStatusValue"
                placeholder="请选择"
                class="select-size"
              >
                <el-option
                  v-for="(item, key) in rechargeStatus"
                  :key="key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="handleQuery">查询</span>
            <span class="clear-button" @click="handleReset">清空</span>
          </div>
        </div>
      </div>

      <el-table
        :data="integralData"
        style="width: 100%"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="isTableLoading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        @sort-change="sortChange"
        row-key="id"
      >

        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed"
        >
         <template slot-scope="{row}">
          <div v-if="col.prop === 'customer_username' || col.prop === 'customer_email'" class="cursor-pointer" @click="jumpToCustomer(row)">
            {{row[col.prop]}}
          </div>
           <!-- <div v-else-if="col.prop === 'customer_email'" class="cursor-pointer" @click="jumpToCustomer(row)">
            {{row[col.prop]}}
          </div> -->
          <div v-else-if="col.prop==='created_at' || col.prop==='updated_at'">{{setTime(row[col.prop])}}</div>
          <div v-else-if="col.prop === 'market'">
            {{row.market && row.market === 'US'? 'US' : 'CN'}}
          </div>
          <div v-else-if="col.prop === 'grandtotal'"  class="cursor-pointer">
            <span>
                {{row.grandtotal ? `${row.market && row.market === 'US' ? '$' : '￥'}${row.grandtotal}` : "--"}}
            </span>
          </div>
          <div v-else-if="col.prop === 'purchase_plan_name'">
            <span v-if="row.purchase_plan_id === 1">个人版</span>
            <span v-else-if="row.purchase_plan_id === 2">专业版</span>
            <span v-else-if="row.purchase_plan_id === 3">自定义</span>
            <span v-else>{{ row.purchase_plan_name||'海外市场固定充值方案' }}</span>
          </div>
          <div v-else-if="col.prop === 'zip'">
              <span>{{ row[col.prop] || "--" }}</span>
          </div>
          <div v-else-if="col.prop === 'payment_method'">
            <span v-if="row.payment_method === 1">微信</span>
            <span v-if="row.payment_method === 2">支付宝</span>
            <span v-if="row.payment_method === 'Stripe'">Stripe</span>
          </div>
          <div v-else-if="col.prop === 'relation_order'" class="cursor-pointer" @click="handleOrder(row)">
               {{ row.relation_order || "--" }}
          </div>
          <div v-else-if="col.prop === 'status'">
              {{ row[col.prop] === 0 ? '付款中' : row[col.prop] === 1 ? '正常' : '已退款' }}
          </div>
          <div v-else-if="col.prop === 'operation'" class="icon-btn">
              <el-button
                type="text"
                size="small"
                @click="sendEmail(row)"
                v-if="hasResource('order_email')"
              >
                <i class="iconfont">&#xe661;</i>补发邮件
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="row.status === 1 && hasResource('order_refund')&& row.market&&row.market === 'US'"
                @click="handleRefund(row)"
              >
                <i class="iconfont">&#xe608;</i>退款
              </el-button>
          </div>
          <div v-else>{{row[col.prop]}}</div>
         </template>
        </el-table-column>
      </el-table>

      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>

      <!-- 退款 -->
      <el-dialog
        top="12%"
        :visible.sync="dialogVisibleRefund"
        width="458px"
        :modal-append-to-body="false"
        class="pop-dialog promo-add-dialog"
      >
        <div class="chinese-bold fz-18">
          您将对用户
          <span class="fz-24 bold">{{ username }}</span> 进行退款操作
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="confirm-button"
            size="medium"
            @click="refundSure"
            :loading="isrefundSure"
            >确认</el-button
          >
          <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 补发邮件 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleResend"
        width="458px"
        :modal-append-to-body="false"
        class="pop-dialog promo-add-dialog"
      >
        <div>
          <div class="chinese-bold fz-18">
            您将为用户
            <span class="fz-24 bold">{{ username }}</span> 补发订单邮件
          </div>
          <div class="display-flex mg-t-24">
            <div class="line-height-48">
              <span>用户邮箱</span>
            </div>
            <div class="mg-l-28">
              <el-input v-model="resendEmail"></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="confirm-button"
            size="medium"
            @click="resendSure"
            :loading="btnLoading"
            >确认</el-button
          >
          <el-button class="cancel-button" @click="dialogVisibleResend = false"
            >取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { formatDate } from "ecoplants-lib";
import commonApi from "../../../api/common";
import orderApi from "../../../api/order";
export default {
  data() {
    return {
      header: {
        url: "orders",
      },
       tableColumns: [
        { prop: "order_number", label: "订单号", width: 165, align: "left" , fixed: "left" },
        { prop: "created_at", label: "下单时间", width: 160, align: "left" },
        { prop: "updated_at", label: "市场时间", width: 160, align: "left" },
        { prop: "customer_username", label: "用户名称", width: 120, align: "left" },
        { prop: "customer_email", label: "用户邮箱", width: 160, align: "left" },
        { prop: "purchase_plan_id", label: "积分方案编号", width: 120, align: "left" },
        { prop: "purchase_plan_name", label: "积分方案名称", width: 120, align: "left" },
        { prop: "market", label: "市场", width: 120, align: "left" },
        { prop: "zip", label: "货币积分比", width: 120, align: "left" },
        { prop: "payment_method", label: "支付方式", width: 120, align: "left" },
        { prop: "transaction_id", label: "交易单号", width: 165, align: "left" },
        { prop: "relation_order", label: "关联单号", width: 165, align: "left" },
        { prop: "grandtotal", label: "订单金额", width: 120, align: "left" },
        { prop: "grandtotal", label: "到账金额", width: 120, align: "left" },
        { prop: "balance", label: "到账积分", width: 120, align: "left" },
        { prop: "status", label: "状态", width: 120, align: "left" },
        { prop: "operation", label: "操作", width: 260, align: "left",fixed:"right"},
      ],
      filter: {
        rechargeId: "",
        rechargeTime: "",
        rechargeName: "",
        customer_email: "",
        customer_username: "",
        rechargeNumber: "",
        market: "US",
        trasactionId: "",
        assaociatedNum: "",
      },
      rechargeStatus: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "已退款",
        },
      ],
      rechargeStatusValue: "",
      market: "",
      isTableLoading: false,
      integralData: [],
      pagesize: 10,
      total: 0,
      currentPage: 1,
      order_by: "created_at",
      sort: "DESC",
      dialogVisibleRefund: false,
      username: "",
      refundId: "",
      dialogVisibleResend: false,
      resendEmail: "",
      resendId: "",
      exportInfo: "",
      isLoading: false,
      rechargeTime: [],
      isrefundSure: false,
      btnLoading: false,
    };
  },
  methods: {
    init() {
      this.getAllMarket();
      this.getOrderList();
    },
    getOrderList() {
      this.isTableLoading = true;
      let data = {
        limit: this.pagesize,
        page: this.currentPage,
        order_by: this.order_by,
        sort: this.sort,
        filter: {
          customer_username_email: this.filter.rechargeName,
          customer_email: this.filter.customer_email,
          customer_username: this.filter.customer_username,
          purchase_plan_id: this.filter.rechargeNumber,
          order_number: this.$route.query.order_number
            ? this.$route.query.order_number
            : this.filter.rechargeId, // 订单号
          transaction_id: this.filter.trasactionId, //交易单号
          relation_order: this.filter.assaociatedNum,
          market: this.filter.market,
          status: this.rechargeStatusValue,
          created_at: {
            from: this.rechargeTime[0],
            to: this.rechargeTime[1],
          },
        },
      };
      this.exportInfo = data.filter;
      orderApi.orderList(data).then((res) => {
        if (res.data.code === 10200) {
          this.integralData = res.data.data.items;
          if(res.data.data.total){
              this.total = Number(res.data.data.total);
          }else{
              this.total = res.data.data.total;
          }
          if (this.integralData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getUserList();
          }
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    getAllMarket() {
      commonApi.market().then((res) => {
        if (res.data.code === 10200) {
          this.market = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.rechargeTime = [];
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.rechargeTime = [];
      this.getOrderList();
    },
    // 查询
    handleQuery() {
      this.currentPage = 1;
      this.isTableLoading = true;
      let data = {
        limit: this.pagesize,
        page: this.currentPage,
        order_by: this.order_by,
        sort: this.sort,
        filter: {
          customer_username_email: this.filter.rechargeName,
          customer_email: this.filter.customer_email,
          customer_username: this.filter.customer_username,
          purchase_plan_id: this.filter.rechargeNumber,
          order_number: this.filter.rechargeId, // 订单号
          transaction_id: this.filter.trasactionId, //交易单号
          relation_order: this.filter.assaociatedNum,
          market: this.filter.market,
          status: this.rechargeStatusValue,
          created_at: {
            from: this.rechargeTime[0],
            to: this.rechargeTime[1],
          },
        },
      };
      this.exportInfo = data.filter;
      orderApi.orderList(data).then((res) => {
        if (res.data.code === 10200) {
          this.integralData = res.data.data.items;
          if(res.data.data.total){
              this.total = Number(res.data.data.total);
          }else{
              this.total = res.data.data.total;
          }
          if (this.integralData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getUserList();
          }
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    handleReset() {
      this.filter = {
        rechargeId: "",
        rechargeTime: [],
        rechargeName: "",
        customer_email: "",
        customer_username: "",
        rechargeNumber: "",
        market: "",
        trasactionId: "",
        assaociatedNum: "",
      };
      this.rechargeStatusValue = "";
      this.rechargeTime = [];
    },
    // 退款
    handleRefund(row) {
      this.dialogVisibleRefund = true;
      this.username = row.customer_username;
      this.refundId = row.id;
    },
    refundSure() {
      this.isrefundSure = true;
      let data = {
        id: this.refundId,
      };
      orderApi.orderRefund(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success(res.data.message);
          this.getOrderList();
        } else {
          this.$$error(res.data.message);
        }
        this.dialogVisibleRefund = false;
        this.isrefundSure = false;
      });
    },
    hide() {
      this.dialogVisibleRefund = false;
    },
    // 补发邮件
    sendEmail(row) {
      this.dialogVisibleResend = true;
      this.username = row.customer_username;
      this.resendEmail = row.customer_email;
      this.resendId = row.id;
    },
    resendSure() {
      this.btnLoading = true;
      let data = {
        id: this.resendId,
        market: this.filter.market,
      };
      orderApi.resendEmail(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleResend = false;
          this.getOrderList();
          this.$$success("发送成功");
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading = false;
      });
    },
    // 导出
    exportData() {
      this.isLoading = true;
      let data = this.exportInfo;
      orderApi.orderExport(data).then((res) => {
        if (res.data.code === 10200) {
          this.downloadFile(res.data.data.export_url);
        } else {
          this.$$error(res.data.message);
        }
        this.isLoading = false;
      });
    },

    // 关联单号跳转
    handleOrder(row) {
      this.$router.push({
        path: "/transactions/refunds",
        query: {
          relation_order: row.relation_order,
        },
      });
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.getOrderList();
    },

    // 用户名称跳转
    jumpToCustomer(row) {
      this.$router.push({
        path: "/customer",
        query: {
          id: row.customer_id,
        },
      });
    },
    setTime(val) {
        return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
    }
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less">
@import "./scss/index.less";
</style>
